import { mergeDifferences } from '@/low_code/utils/tools';
import _ from 'lodash';
import { observable, action, toJS } from 'mobx';

export default class LowCodeStore {
  @observable markData: any = {
    isPreview: false,
    jsonPathData: '',
    outputData: '',
    isValid: true,
    // 记录文本划选标签部分下拉打开状态，提交跳过等操作要保留打开状态，数组存哪个文本划选需要记录
    textSelectionLabelOpens: [],
    // adaptScreen通知图像标框组件重置大小，adaptScreenOver重置完成执行后续操作
    adaptScreen: false,
    adaptScreenOver: false
  };

  // 这个数据中存储映射值，存储的是预览或标注等地方修改的内容，坐标，key，用于最后生成输出信息时使用
  @observable previewMarkData: any = [];

  // 回显数据映射表，这个数据存储服务器返回的已标注的内容，在各个组件中进行内容回显
  @observable EchoMappingData: any = [];

  // 存储图像标框组件实例，用于提交时根据实例获取缩略图,数据结构为对象{key:'',value:object}，key值确认实例，value存储内容
  @observable ImgMarkData: any = [];

  @observable splitScreenData: any = {
    isPreview: false,
    jsonPathData: '',
    outputData: '',
    isValid: true,
    // 记录文本划选标签部分下拉打开状态，提交跳过等操作要保留打开状态，数组存哪个文本划选需要记录
    textSelectionLabelOpens: [],
    // adaptScreen通知图像标框组件重置大小，adaptScreenOver重置完成执行后续操作
    adaptScreen: false,
    adaptScreenOver: false
  };

  @observable splitScreenEchoMappingData: any = [];

  // 这个数据中存储映射值，存储的是预览或标注等地方修改的内容，坐标，key，用于最后生成输出信息时使用
  @observable splitScreenPreviewMarkData: any = [];

  // 存储图像标框组件实例，用于提交时根据实例获取缩略图,数据结构为对象{key:'',value:object}，key值确认实例，value存储内容
  @observable splitScreenImgMarkData: any = [];

  @action
  updateJsonPathData(markData: any): void {
    this.markData = {
      ...this.markData,
      ...markData
    };
  }

  @action
  updateSplitScreenJsonPathData(splitScreenData: any): void {
    this.splitScreenData = {
      ...this.splitScreenData,
      ...splitScreenData
    };
  }

  @action
  getMarkData(key: string): any {
    return this.markData[key];
  }

  @action
  clearMarkData(): void {
    this.markData = {
      isPreview: false,
      jsonPathData: '',
      outputData: '',
      isValid: true,
      textSelectionLabelOpens: []
    };
  }

  @action
  getPreviewMarkData(): any {
    return this.previewMarkData;
  }

  @action
  getSplitScreenPreviewMarkData(): any {
    return this.splitScreenPreviewMarkData;
  }

  // 修改的时候根据坐标和key值来判断是现有数据中是否有这个值，如果有就更新，如果没有就添加
  @action
  updatePreviewMarkData(previewMarkData: any): void {
    const arr = _.cloneDeep(this.previewMarkData);
    let isChange = false;
    const result = [];

    for (let i = 0; i < this.previewMarkData.length; i += 1) {
      for (let j = 0; j < previewMarkData.length; j += 1) {
        if (
          this.previewMarkData[i].index === previewMarkData[j].index &&
          this.previewMarkData[i].key === previewMarkData[j].key
        ) {
          result.push(
            mergeDifferences(toJS(this.previewMarkData[i]), previewMarkData[j])
          );
          isChange = true;
        } else {
          result.push(this.previewMarkData[i]);
        }
      }
    }
    if (isChange) {
      this.previewMarkData = _.cloneDeep(result);
    } else {
      this.previewMarkData = [...this.previewMarkData, ...previewMarkData];
    }
  }

  // 修改的时候根据坐标和key值来判断是现有数据中是否有这个值，如果有就更新，如果没有就添加
  @action
  updateSplitScreenPreviewMarkData(splitScreenPreviewMarkData: any): void {
    const arr = [...this.splitScreenPreviewMarkData];
    let isChange = false;
    const result = [];

    for (let i = 0; i < this.splitScreenPreviewMarkData.length; i += 1) {
      for (let j = 0; j < splitScreenPreviewMarkData.length; j += 1) {
        if (
          this.splitScreenPreviewMarkData[i].index ===
            splitScreenPreviewMarkData[j].index &&
          this.splitScreenPreviewMarkData[i].key ===
            splitScreenPreviewMarkData[j].key
        ) {
          result.push(
            mergeDifferences(
              toJS(this.splitScreenPreviewMarkData[i]),
              splitScreenPreviewMarkData[j]
            )
          );
          isChange = true;
        } else {
          result.push(this.splitScreenPreviewMarkData[i]);
        }
      }
    }
    if (isChange) {
      this.splitScreenPreviewMarkData = [...result];
    } else {
      this.splitScreenPreviewMarkData = [
        ...this.splitScreenPreviewMarkData,
        ...splitScreenPreviewMarkData
      ];
    }
  }

  @action
  clearPreviewMarkData(): void {
    this.previewMarkData = [];
  }

  @action
  clearSplitScreenPreviewMarkData(): void {
    this.splitScreenPreviewMarkData = [];
  }

  @action
  clearEchoMappingData(): void {
    this.EchoMappingData = [];
  }

  @action
  clearSplitScreenEchoMappingData(): void {
    this.splitScreenEchoMappingData = [];
  }

  @action
  getEchoMappingData(): any {
    return this.EchoMappingData;
  }

  @action
  getSplitScreenEchoMappingData(): any {
    return this.splitScreenEchoMappingData;
  }

  @action
  updateEchoMappingData(EchoMappingData: any): void {
    this.EchoMappingData = [...EchoMappingData];
  }

  @action
  updatePartEchoMappingData(EchoMappingData: any): void {
    if (EchoMappingData.length === 0) {
      return;
    }
    const arr = _.cloneDeep(this.EchoMappingData);
    let isChange = false;
    // @ts-ignore
    const result = [];
    // 没有数据时直接覆盖
    if (this.EchoMappingData.length === 0 && EchoMappingData.length != 0) {
      this.EchoMappingData = _.cloneDeep(EchoMappingData);
      return;
    }

    if (this.EchoMappingData.length !== 0 && EchoMappingData.length !== 0) {
      EchoMappingData.forEach((item: any) => {
        const findIdx = this.EchoMappingData.findIndex(
          (i: any) => i.key === item.key && i.index === item.index
        );
        if (findIdx !== -1) {
          this.EchoMappingData[findIdx] = mergeDifferences(
            toJS(this.EchoMappingData[findIdx]),
            item
          );
          isChange = true;
        } else {
          this.EchoMappingData.push(item);
        }
      });
    }
  }

  @action
  updateSplitScreenEchoMappingData(splitScreenEchoMappingData: any): void {
    this.splitScreenEchoMappingData = [
      ...this.splitScreenEchoMappingData,
      ...splitScreenEchoMappingData
    ];
  }

  @action
  mergeEchoMappingPreviewData(): void {
    const hasKey = (
      previewMarkData: any,
      key: string,
      index: number
    ): boolean => {
      for (let i = 0; i < previewMarkData.length; i += 1) {
        if (
          key === previewMarkData[i].key &&
          previewMarkData[i].index === index
        ) {
          return true;
        }
      }
      return false;
    };
    if (this.previewMarkData.length === 0) {
      this.previewMarkData = this.EchoMappingData;
    }
    for (let i = 0; i < this.EchoMappingData.length; i += 1) {
      const key = this.EchoMappingData[i].key;
      const index = this.EchoMappingData[i].index;
      if (!hasKey(this.previewMarkData, key, index)) {
        this.previewMarkData.push(this.EchoMappingData[i]);
      }
    }
  }

  @action
  mergeSplitScreenEchoMappingPreviewData(): void {
    const hasKey = (
      splitScreenEchoMappingData: any,
      key: string,
      index: number
    ): boolean => {
      for (let i = 0; i < splitScreenEchoMappingData.length; i += 1) {
        if (
          key === splitScreenEchoMappingData[i].key &&
          splitScreenEchoMappingData[i].index === index
        ) {
          return true;
        }
      }
      return false;
    };
    if (this.splitScreenEchoMappingData.length === 0) {
      this.splitScreenEchoMappingData = this.EchoMappingData;
    }
    for (let i = 0; i < this.EchoMappingData.length; i += 1) {
      const key = this.EchoMappingData[i].key;
      const index = this.EchoMappingData[i].index;
      if (!hasKey(this.splitScreenEchoMappingData, key, index)) {
        this.splitScreenEchoMappingData.push(this.EchoMappingData[i]);
      }
    }
  }

  @action
  clearImgMarkData(): void {
    this.ImgMarkData = [];
  }

  @action
  clearSplitScreenImgMarkData(): void {
    this.splitScreenImgMarkData = [];
  }

  @action
  updateImgMarkData(ImgMarkData: any): void {
    const map: any = {};
    this.ImgMarkData.forEach((item: { key: string | number; value: any }) => {
      map[item.key] = item.value;
    });
    ImgMarkData.forEach((item: { key: string | number; value: any }) => {
      map[item.key] = item.value;
    });
    const result = Object.keys(map).map((key) => ({
      key,
      value: map[key]
    }));
    this.ImgMarkData = _.cloneDeep(result);
  }

  @action
  updateSplitScreenImgMarkData(splitScreenImgMarkData: any): void {
    const map: any = {};
    this.splitScreenImgMarkData.forEach(
      (item: { key: string | number; value: any }) => {
        map[item.key] = item.value;
      }
    );
    splitScreenImgMarkData.forEach(
      (item: { key: string | number; value: any }) => {
        map[item.key] = item.value;
      }
    );
    const result = Object.keys(map).map((key) => ({
      key,
      value: map[key]
    }));
    this.splitScreenImgMarkData = [...result];
  }
}
